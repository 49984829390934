import React, {useEffect, useState} from 'react';
import uuid, { v4 as uuidv4 } from 'uuid';
import {stateSelector} from "../reducers";
import {Posts, usePendingRefs} from "../Hooks/Posting";
import {DeNormalize} from "../types/generic";
import {Master} from "../types/master";
import {Button, message, Select} from "antd";
import moment from "moment";
import {useDispatch} from "react-redux";
import {LOADING_END, LOADING_START} from "../actions/uiActions";
import {postNewJournal} from "../api";
import {fetchPostingWithDate} from "../actions/postingActions";
import {FetchMasters} from "../actions/masterActions";
const ID = (): number=> Math.random()*1000;

const UUID = ()  => uuidv4();

const generateNewRef = (master_id:number)=>{
    return{
        master_id,
        ref_no: UUID(),
        amount:0,
    }
}

const Receipt2 = ()=>{
    let debitOrCredit = "debit";
    const masters = stateSelector( stt => stt.master.masters);
    const cmpnyId = stateSelector(stt => stt.sys.SelectedCompany);
    let [selectedMaster, setSelectedMaster] = useState(masters.normalized[masters.all[0]]);
    const [ refs, loadingRefs, errorRefs, mutateRefs ] = usePendingRefs(selectedMaster.id);
    const [filteredrefs, setFileterdRefs ] = useState<Posts[]>([]);
    const normalizedMaster = DeNormalize<Master>(masters);
    const filteredMaster = normalizedMaster.filter((mstr) => mstr.group_id ===6 || mstr.group_id===5);
    const [ cashAccount, setCashAccount ] = useState(filteredMaster[0].cust_id.Int64 || 0)
    const [ newRefs, setNewRefs ] =useState<Array<Posts>>([]);
    const [ sums, setSums ] = useState(0);
    const [currentDate, setCurrentDate] = useState(moment().format("YYYY-MM-DD"));

    const [ refAmounts, setRefAmounts ] = useState<{[key:string]: number}>({  });
    const dispatch = useDispatch();
    // const [ netPaid,  ]


    const addNewRef = ()=>{
        const delta = [...newRefs, generateNewRef(selectedMaster.id)];
        setNewRefs(delta);
    }

    const addRefAmount = (ref: string, amount: number)=>{
        // set new map with value
        const newAmounts = {...refAmounts, [ref]: amount};
        setRefAmounts(newAmounts);
    }

    const deleteRef = (ref_id: string)=>{
        const delta = newRefs.filter((ref)=>ref.ref_no !== ref_id);
        setNewRefs(delta);
    }

    const saveJournal = async ()=>{
        const confirm = window.confirm("Save?");
        if(!confirm)return;
        // create postings..

        let postings: any[] = []
        for(const key in refAmounts){
            const posting = {
                ref_no: key,
                master_id: selectedMaster.id,
                amount: debitOrCredit==="debit"?refAmounts[key]:-refAmounts[key]
            }
            postings = [...postings, posting]
        }
        const posting = {
            ref_no: "",
            master_id: cashAccount,
            amount: debitOrCredit==="debit"?-sums:sums
        }
        postings = [...postings, posting]
        const journal = {
            id:0,
            ref_no:"",
            company_id: cmpnyId,
            date: currentDate,
            sttmt_id: undefined,
            type:"Reciept2",
            narration:"",
            postings
        }
        try{
            dispatch({ type: LOADING_START});
            const resp = await postNewJournal(journal);
            if(resp.status==200){
                message.success("Saved receipt")
                setRefAmounts({});
                await mutateRefs();
                await dispatch(FetchMasters())
            }


        }catch (e) {
            message.error("Failed to save")
        }finally {
            dispatch({ type: LOADING_END});
        }



    }

    useEffect(()=>{
        if(refs && refs.data    ) {
            const fRefs = refs.data.filter((ref:Posts) => (debitOrCredit === "debit" ? ref.amount < 0 : ref.amount >= 0));
            setFileterdRefs(fRefs);
        }
    },[refs])

    useEffect(()=>{
        let sum = 0
        for( const key in refAmounts){
            sum += refAmounts[key]
        }
        setSums(sum);

    },[refAmounts])

    return(
        <div>
            <h1>Receipts with refs</h1>
            <input
                type="date"
                style={{float: "right", outline: "none", border: "none"}}
                onChange={e => {
                    setCurrentDate(e.target.value);
                }}
                defaultValue={currentDate}
                value={currentDate}
            />
            Credits:<select
                onChange={(e)=>{
                    setSelectedMaster(
                        masters.normalized[e.target.value]
                    )
                }}
            >
                {
                    masters.all.map((idx)=>{
                        const master = masters.normalized[idx];
                        return <option key={idx} value={master.id}>{ master.name }</option>
                    })
                }
            </select>
            {
                refs && refs.data ? <div>
                    <table>
                        <thead>
                        <tr>
                            <td>
                                S. No.
                            </td>
                            <td>
                                Reference
                            </td>
                            <td>
                                Pending Amount
                            </td>
                            <td>
                                Amount Paid
                            </td>
                        </tr>
                        </thead>
                        {
                            filteredrefs.map((ref: Posts, id: number)=>
                                <tr key={ref.ref_no}>
                                    <td>{ id+1 }</td>
                                    <td>{ref.ref_no}</td>
                                    <td>{Math.abs(ref.amount)}{
                                        ref.amount<0?"DR":"CR"
                                    }</td>
                                    <td>
                                        <input onBlur={(e)=>{
                                            try{
                                                const val = parseInt(e.target.value);
                                                if(!val) return
                                                addRefAmount(ref.ref_no, val);
                                            }catch (e) {
                                                console.log("Unable to parse")
                                            }

                                        }} type="number" />
                                    </td>
                                </tr>
                            )
                        }
                        {
                            newRefs.map((ref)=><tr key={ref.ref_no}>
                                <td><Button
                                    onClick={()=>{deleteRef(ref.ref_no)}}
                                    type={"danger"} >X</Button></td>
                                <td colSpan={2}>{ref.ref_no}</td>
                                <td>
                                    <input onBlur={(e)=>{
                                        try{
                                            const val = parseInt(e.target.value);
                                            if(!val) return
                                            addRefAmount(ref.ref_no, val);
                                        }catch (e) {
                                            console.log("Unable to parse")
                                        }

                                    }} type="number" />
                                </td>
                            </tr>)
                        }
                    <tfoot>
                    <tr>
                        <td colSpan={3}>Net Amount</td>
                        <td>{sums}</td>
                    </tr>
                    </tfoot>

                    </table>
                    <Button onClick={()=>{addNewRef()}}>New Ref</Button>

                    Debits:<Select onChange={(e) => {
                        setCashAccount(e)
                    }} value={cashAccount} style={{width: "200px", marginTop: 10}}>
                        {
                            filteredMaster.map((master) =>
                                <Select.Option key={master.cust_id?.Int64}
                                               value={master.cust_id?.Int64}>{master.name}</Select.Option>)
                        }
                    </Select>
                </div> :null
            }
            <Button onClick={saveJournal} >Save</Button>
        </div>
    )
}

export default Receipt2;