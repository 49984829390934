import React, {useState, useRef, useEffect, FC} from 'react'
import {stateSelector} from "../reducers";
import {Button, Modal, Select} from "antd";
import MasterList from "./mstrlist";
import './VoucherRow.css';
import {useDispatch} from "react-redux";
import {ToggleMasterForm} from "../actions/uiActions";

interface Props {
  onSelect(cursor:number):void;
  cursor?:number;
}

const NameSelect:FC<Props> = (props)=>{

  const [ showList, setShowList ] = useState(false);
  const [ curKey, setCurKey ] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);
  const masters = stateSelector( state => state.master.masters );
  const isMobile = stateSelector(state=>state.ui.mobileMode);
  const [selected, setSelected ] = useState(props.cursor?.toString() || undefined);
  const initialName = props.cursor? masters.normalized[props.cursor]?.name || "" :""
  const [ selectedMaster, setSelectedMaster ] = useState(initialName);
  const dispatch = useDispatch();
  const { onSelect } = props;
  const focus = ()=>{
    if(inputRef.current){
      inputRef.current.focus();
      inputRef.current.scrollIntoView();
    }
  }

  const toggleList = ()=>{
    setShowList(!showList);
    focus();
  }

  useEffect(()=>{
    focus();
  },[]);

  const setMaster = async (cursor: number)=>{
    if(masters){
      const name = masters.normalized[cursor].name;
      await setSelectedMaster(name);
      await onSelect(cursor)
      toggleList();
    }
  }
  const OnChange = async (value: string) =>{
    const cursor = parseInt(value);
    if(masters){
      const name = masters.normalized[cursor].name;
      setSelectedMaster(name)
      setSelected(value)
      await onSelect(cursor)
      toggleList();
    }
  }
  const createNewMaster = ()=>{
      dispatch(ToggleMasterForm(true, undefined));
  }
  const { Option } = Select;

  if(isMobile){
    return (
        <div style={{ minWidth:"60%", width:"50%"}}>
        <Select
            showSearch
            placeholder="Choose a master"
            value={selected=="1"?undefined:selected}
            onChange={OnChange}
            filterOption={(input, option) =>
                (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
            }
            style={{ width: "100%" }}
            dropdownRender={menu => (
                <>
                  {menu}
                    <Button type="ghost" onClick={createNewMaster}>
                      Create Master
                    </Button>
                </>
            )}
        >
          {
            masters.all.map((id)=><Option key={id} value={id.toString()}>{masters.normalized[id].name}</Option>)
          }
        </Select>
        </div>
    )
  }

  return(
    <div style={{ display: "inline", width:"100%"}}>
      <input
        onKeyPress={(e)=>{ if(e.keyCode!==13){setCurKey(e.key);} toggleList()}}
        value = {selectedMaster}
        placeholder={"Press any key to enter"}
        ref={inputRef}
        style={{ "width": "100%"}}
        className={"covert-input"}
      />
      <Modal
        title={"Select Master"}
        visible={showList}
        onCancel={toggleList}
        footer={null}
        destroyOnClose
        style={{ zIndex: 50, position:"fixed", width:"100%", height:"100%" , top: 5, animationDuration:"0s !important"}}
        transitionName="none"
        maskTransitionName="none"
      >
        <MasterList  masters={masters} initFilter={curKey} handleEnter={setMaster} handleEscape={()=>{}}   />
      </Modal>
    </div>
  )

}

export default NameSelect;