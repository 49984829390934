import useSWR from "swr";
import {GeneralResponse} from "../types/response";
import {fetcher} from "../api/base";


export interface Posts {
    master_id: number;
    ref_no: string;
    amount: number;
}

export const usePendingRefs = (masterID: number) => {
    const { data, error, mutate } = useSWR<GeneralResponse<Posts[]>>(`/refs/${masterID}`,
        fetcher)
    return [ data, !data, error , mutate]
}