import styled from "styled-components";

export const Select = styled.select`
  height: 30px;
`;

// export const PageDiv = styled.div`
//   background-color: #1b1f23;
//   width: 100%;
//   height: 100vh;
//   min-height: 100% !important;
//   padding-left: 10px;
//   color: white;
//
//   h1 {
//     color: white;
//   }
//
// `


export const PageDiv = styled.div`
  background-color: #fff;
  width: 100%;
  height: 100vh;
  min-height: 100% !important;
  color: black;
  overflow-y: scroll;
  display:flex;
  flex-direction: column;
  h1 {
    color: black;
  }
  
`



