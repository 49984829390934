import React, { useEffect, useState, ChangeEvent } from "react";
import { ax } from "../api/base";
import { Journal } from "../types/ledger";
import { stateSelector } from "../reducers";
import { PageDiv } from "../components/styledComp";
import Nav from "../components/nav";
import KeyList from "../components/keylist";
import { DeNormalize, normalize, RenderItemProps } from "../types/generic";
import { SELTR } from "./index";
import moment from "moment";
import { SimTd } from "../components/sttmntTR";
import { useDispatch } from "react-redux";
import { FetchJournal } from "../actions/uiActions";
import { DatePicker, Input } from "antd";

const { RangePicker } = DatePicker;

const DayBook = () => {
  const [journals, setJournals] = useState<Array<Journal>>([]);
  const [filtered, setFiltered] = useState(journals);
  const [filterByText, setFilterByText] = useState("");
  const companyID = stateSelector((s) => s.sys.SelectedCompany);
  const [sd, setSd] = useState("");
  const [ed, setEd] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    ax.get(`/journals?sd=${sd}&ed=${ed}`, {
      headers: {
        authorization: companyID,
      },
    }).then((res) => {
      setJournals(res.data);
      setFiltered(res.data);
    });
  }, [sd, ed]);

  const cols = ["Date", "Narration", "Reference", "Type", "Debit", "Credit"];

  const handleEnter = (cursor: number) => {
    // const journal =
    dispatch(FetchJournal(cursor));
  };
  const handleFilterChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterByText(e.target.value);
  };

  const render = (args: RenderItemProps<Journal>) => {
    const amount = args.item.amount;
    return (
      <SELTR>
        <SimTd>{moment(args.item.date).format("MMM Do")}</SimTd>
        <SimTd>{args.item.narration}</SimTd>
        <SimTd>{args.item.ref_no}</SimTd>
        <SimTd>{args.item.type}</SimTd>
        <SimTd>{amount && amount <= 0 ? Math.abs(amount) : null}</SimTd>
        <SimTd>{amount && amount > 0 ? Math.abs(amount) : null}</SimTd>
      </SELTR>
    );
  };

  useEffect(() => {
    // console.log(`Received filter ${filterByText}`);
    if (
      journals &&
      typeof filterByText !== "undefined" &&
      filterByText !== ""
    ) {
      const newFilter = journals.filter((denom) => {
        let total_text = `${denom.date}||${denom.narration}||
                            ${denom.ref_no}||${denom.type}||${denom.amount}`.toLowerCase();
        // console.log(total_text);
        return total_text.indexOf(filterByText.toLowerCase()) >= 0;
      });
      setFiltered(newFilter);
    } else {
      if (journals) setFiltered(journals);
    }
  }, [filterByText]);

  return (
    <PageDiv>
      <Nav />
      <div>
        <h2>Day Book</h2>
        <div>
          <Input
            value={filterByText}
            onChange={handleFilterChange}
            placeholder={"Filter by any"}
            style={{ height: 60, width: "40%", margin: "10px" }}
          />
        </div>
        <RangePicker
          onChange={(date, dateString) => {
            // console.log("Datestrings: ", { dateString });
            setSd(dateString[0]);
            setEd(dateString[1]);
          }}
        />
        <div>
          <KeyList
            handleEnter={handleEnter}
            cursor={0}
            autoFocus={false}
            data={normalize(filtered)}
            renderItem={render}
            columns={cols}
            rowHeight={20}
            numberOfRows={14}
            maxHeight={500}
          />
        </div>
      </div>
    </PageDiv>
  );
};

export default DayBook;
